import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    inputs: Object,
  };

  async connect() {
    try {
      // Dynamically import Highcharts when this controller is initialized
      const { default: Highcharts } = await import('highcharts');
      
      // Process input values
      const data = {
        revenue: this.inputsValue.annual_income_statements_revenue.map(str => Number(str)),
        netIncome: this.inputsValue.annual_income_statements_net_income.map(str => Number(str)),
        grossProfit: this.inputsValue.annual_income_statements_gross_profit.map(str => Number(str)),
        operatingIncome: this.inputsValue.annual_income_statements_operating_income.map(str => Number(str)),
        years: this.inputsValue.annual_income_statements_years.map(str => Number(str)),
        currency: this.inputsValue.annual_income_statements_reported_currency
      };

      // Global Highcharts settings
      Highcharts.setOptions({
        lang: { numericSymbols: ["Th", "M", "B", "T"] },
        credits: { enabled: false },
        colors: [
          "#4285F4", // Google Blue
          "#DB4437", // Google Red
          "#F4B400", // Google Yellow
          "#0F9D58", // Google Green
          "#AB47BC", // Purple
          "#00ACC1", // Cyan
          "#FF7043", // Deep Orange
          "#9E9E9E", // Grey
          "#5C6BC0", // Indigo
          "#F06292", // Pink
        ],
      });

      // Render the first chart
      Highcharts.chart("container", {
        chart: { type: "column" },
        title: { text: "Revenue Vs Earnings Trend" },
        xAxis: { categories: data.years },
        yAxis: { 
          title: { text: `Value in ${data.currency}` }
        },
        credits: { enabled: false },
        plotOptions: {
          column: { borderRadius: "0%" }
        },
        series: [
          { name: "Revenue", data: data.revenue },
          { name: "Earnings", data: data.netIncome }
        ],
      });

      // Render the second chart
      Highcharts.chart("container2", {
        title: { text: "Income Distribution" },
        yAxis: {
          title: { text: `Value in ${data.currency}` }
        },
        xAxis: { categories: data.years },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle"
        },
        plotOptions: {
          series: {
            label: { connectorAllowed: false }
          }
        },
        series: [
          { name: "Revenue", data: data.revenue },
          { name: "Gross Profit", data: data.grossProfit },
          { name: "Operating Income", data: data.operatingIncome },
          { name: "Net Income", data: data.netIncome }
        ],
        responsive: {
          rules: [{
            condition: { maxWidth: 500 },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom"
              }
            }
          }]
        }
      });
    } catch (error) {
      console.error('Error loading Highcharts:', error);
    }
  }
}