import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';

export default class extends Controller {
    static values = {
      inputs: Object
    }
    connect() {
        var annual_balance_sheets_years = this.inputsValue.annual_balance_sheets_years.map(str => Number(str));
        var annual_balance_sheets_total_current_assets = this.inputsValue.annual_balance_sheets_total_current_assets.map(str => Number(str));
        var annual_balance_sheets_total_non_current_assets = this.inputsValue.annual_balance_sheets_total_non_current_assets.map(str => Number(str));
        var annual_balance_sheets_total_current_liabilities = this.inputsValue.annual_balance_sheets_total_current_liabilities.map(str => Number(str));
        var annual_balance_sheets_total_non_current_liabilities = this.inputsValue.annual_balance_sheets_total_non_current_liabilities.map(str => Number(str));
        var annual_balance_sheets_total_equity = this.inputsValue.annual_balance_sheets_total_equity.map(str => Number(str));
        var annual_balance_sheets_reported_currency = this.inputsValue.annual_balance_sheets_reported_currency;
        var working_capital = [];
        var current_ratio = [];
        for (let i = 0; i < annual_balance_sheets_total_current_assets.length; i++) {
            working_capital.push(annual_balance_sheets_total_current_assets[i] - annual_balance_sheets_total_current_liabilities[i]);
        }
        for (let i = 0; i < annual_balance_sheets_total_current_assets.length; i++) {
            current_ratio.push(annual_balance_sheets_total_current_assets[i] / annual_balance_sheets_total_current_liabilities[i]);
        }
        
        Highcharts.setOptions({
            lang: {
                numericSymbols: ['Th', ' M', 'B', 'T']
            },
            credits: {
                enabled: false
            }
        });
        
        // Balance Sheet Breakdown Chart
        Highcharts.chart('container3', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Yearly Balance Sheet Breakdown'
            },
            xAxis: {
                categories: annual_balance_sheets_years
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: `Value in ${annual_balance_sheets_reported_currency}`
                },
                labels: {
                    style: {
                        color: '#000000'
                    }
                }
            },
            tooltip: {
                format: '<b>{key}</b><br/>{series.name}: {y}<br/>' +
                    'Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: 'Current Assets',
                data: annual_balance_sheets_total_current_assets,
                stack: 'Europe',
                color: '#3366CC' // Professional blue
            }, {
                name: 'Non-Current Assets',
                data: annual_balance_sheets_total_non_current_assets,
                stack: 'Europe',
                color: '#6699CC' // Lighter blue
            }, {
                name: 'Current Liabilities',
                data: annual_balance_sheets_total_current_liabilities,
                stack: 'North America',
                color: '#E68A00' // Warm orange
            }, {
                name: 'Non-Current Liabilities',
                data: annual_balance_sheets_total_non_current_liabilities,
                stack: 'North America',
                color: '#FFB366' // Light orange
            }, {
                name: 'Equity',
                data: annual_balance_sheets_total_equity,
                stack: 'North America',
                color: '#99CC33' // Fresh green
            }]
        });
        
        // Working Capital Chart
        Highcharts.chart('container4', {
            lang: {
                numericSymbols: ['Th', ' M', 'B', 'T']
            },
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Annual Working Capital',
            },
            subtitle: {
                text: 'Source: ' +
                    '<a href="https://www.yr.no/nb/historikk/graf/5-97251/Norge/Troms%20og%20Finnmark/Karasjok/Karasjok?q=2021"' +
                    'target="_blank">YR</a>',
                align: 'left'
            },
            xAxis: [{
                categories: annual_balance_sheets_years,
                crosshair: true,
                labels: {
                    style: {
                        color: '#000000'
                    }
                }
            }],
            yAxis: [{ 
                labels: {
                    style: {
                        color: '#000000'
                    }
                },
                title: {
                    text: 'Current Ratio',
                    style: {
                        color: '#000000'
                    }
                }
            }, { 
                title: {
                    text: 'Working Capital',
                    style: {
                        color: '#000000'
                    }
                },
                labels: {
                    style: {
                        color: '#000000'
                    }
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            legend: {
                align: 'left',
                x: 80,
                verticalAlign: 'top',
                y: 60,
                floating: true,
                backgroundColor: 'rgba(255,255,255,0.25)'
            },
            series: [{
                name: 'Working Capital',
                type: 'column',
                yAxis: 1,
                data: working_capital,
                color: '#3366CC' // Professional blue
            }, {
                name: 'Currrent Ratio',
                type: 'spline',
                data: current_ratio,
                color: '#E68A00' // Warm orange
            }]
        });
    }
}