import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';


export default class extends Controller {

  static values = {
    inputs: Object
  }

  static targets = [
    "operating_expenses_table_heading",
    "annual_operating_expenses_table",
    "quarterly_operating_expenses_table",
    "annual_operating_expenses_table_toggle_button",
    "quarterly_operating_expenses_table_toggle_button",
  ];

  connect() {

    const chartData = JSON.parse(this.element.getAttribute('data-show-stock-operating-expenses-page-inputs-value'));

    Highcharts.chart('stockShowOperatingExpensesChartContainer', {
      chart: {
        type: 'area',
        zooming: {
          type: 'x'
        },
        panning: true,
        panKey: 'shift',
        scrollablePlotArea: {
          minWidth: 600
        },
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgb(255, 255, 255)'],
            [1, 'rgb(250, 250, 255)']
          ]
        }
      },
    
      title: {
        text: '',
        align: 'left'
      },
    
      credits: {
        enabled: false
      },
    
      xAxis: {
        labels: {
          format: '{value}',
          style: {
            color: '#666666'
          }
        },
        minRange: 1,
        title: {
          text: 'Years',
          style: {
            color: '#666666',
            fontWeight: 'bold'
          }
        },
        lineColor: '#CCCCCC',
        gridLineColor: '#F2F2F2'
      },
    
      yAxis: {
        startOnTick: true,
        endOnTick: false,
        maxPadding: 0.35,
        title: {
          text: 'Operating Expenses',
          style: {
            color: '#666666',
            fontWeight: 'bold'
          }
        },
        labels: {
          format: `{value} ${chartData.reported_currency}`,
          style: {
            color: '#666666'
          }
        },
        gridLineColor: '#F2F2F2'
      },
    
      tooltip: {
        headerFormat: 'Year: {point.x}<br>',
        pointFormat: `Operating Expenses: {point.y} ${chartData.reported_currency}`,
        shared: true,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#E0E0E0',
        borderRadius: 8,
        style: {
          color: '#333333'
        }
      },
    
      legend: {
        enabled: false
      },
    
      series: [{
        data: chartData.annual_data,
        name: 'Operating Expenses',
        lineColor: '#2E5CB8',
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, 'rgba(46, 92, 184, 0.8)'],
            [1, 'rgba(46, 92, 184, 0.1)']
          ]
        },
        fillOpacity: 1,
        marker: {
          enabled: true,
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: '#2E5CB8',
          symbol: 'circle'
        },
        threshold: null
      }],
    
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                format: `{value} ${chartData.reported_currency}`
              }
            },
            tooltip: {
              headerFormat: 'Year: {point.x}<br>',
              pointFormat: `Operating Expenses: {point.y} ${chartData.reported_currency}`
            }
          }
        }]
      }
    });
  }

  annualOperatingExpensesTableToggle(event) {
    event.preventDefault();
    if (this.annual_operating_expenses_tableTarget.classList.contains("hidden")) {
      this.toggleCardsAndHeading("Annual");
      this.toggleButtonClasses(this.annual_operating_expenses_table_toggle_buttonTarget);
      this.toggleButtonClasses(this.quarterly_operating_expenses_table_toggle_buttonTarget);
    }
  }

  quarterlyOperatingExpensesTableToggle(event) {
    event.preventDefault();
    if (this.quarterly_operating_expenses_tableTarget.classList.contains("hidden")) {
      this.toggleCardsAndHeading("Quarterly");
      this.toggleButtonClasses(this.annual_operating_expenses_table_toggle_buttonTarget);
      this.toggleButtonClasses(this.quarterly_operating_expenses_table_toggle_buttonTarget);
    }
  }

  toggleCardsAndHeading(headingText) {
    this.annual_operating_expenses_tableTarget.classList.toggle("hidden");
    this.quarterly_operating_expenses_tableTarget.classList.toggle("hidden");
    this.operating_expenses_table_headingTarget.textContent = headingText;
  }

  toggleButtonClasses(button) {
    const classesToToggle = [
      "bg-gray-100",
      "hover:bg-gray-200",
      "bg-green-600",
      "hover:bg-green-700",
      "text-neutral-900",
      "text-neutral-50",
      "focus:ring-gray-500",
      "focus:border-gray-500",
      "focus:ring-green-500",
      "focus:border-green-500",
    ];

    classesToToggle.forEach((cls) => {
      button.classList.toggle(cls, !button.classList.contains(cls));
    });
  }
}

